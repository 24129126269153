@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes ripple {
  to {
    transform: scale(15);
    opacity: 0;
  }
}

body {
  background: linear-gradient(to bottom, #2b210e, #060501);
  --shimmy-distance: 5;
  --clip-one: polygon(
    0 2%,
    100% 2%,
    100% 95%,
    95% 95%,
    95% 90%,
    85% 90%,
    85% 95%,
    8% 95%,
    0 70%
  );
  --clip-two: polygon(
    0 78%,
    100% 78%,
    100% 100%,
    95% 100%,
    95% 90%,
    85% 90%,
    85% 100%,
    8% 100%,
    0 78%
  );
  --clip-three: polygon(
    0 44%,
    100% 44%,
    100% 54%,
    95% 54%,
    95% 54%,
    85% 54%,
    85% 54%,
    8% 54%,
    0 54%
  );
  --clip-four: polygon(
    0 0,
    100% 0,
    100% 0,
    95% 0,
    95% 0,
    85% 0,
    85% 0,
    8% 0,
    0 0
  );
  --clip-five: polygon(
    0 0,
    100% 0,
    100% 0,
    95% 0,
    95% 0,
    85% 0,
    85% 0,
    8% 0,
    0 0
  );
  --clip-six: polygon(
    0 40%,
    100% 40%,
    100% 85%,
    95% 85%,
    95% 85%,
    85% 85%,
    85% 85%,
    8% 85%,
    0 70%
  );
  --clip-seven: polygon(
    0 63%,
    100% 63%,
    100% 80%,
    95% 80%,
    95% 80%,
    85% 80%,
    85% 80%,
    8% 80%,
    0 70%
  );
}

.tilt {
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.full-page {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.copy-card {
  box-shadow: inset 0px 5.01302px 10.026px rgba(0, 0, 0, 0.12);
}

.animate-glitch {
  animation: glitch 2s infinite;
}

@keyframes glitch {
  0% {
    clip-path: var(--clip-one);
  }
  2%,
  8% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }
  6% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  9% {
    clip-path: var(--clip-two);
    transform: translate(0, 0);
  }
  10% {
    clip-path: var(--clip-three);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  13% {
    clip-path: var(--clip-three);
    transform: translate(0, 0);
  }
  14%,
  21% {
    clip-path: var(--clip-four);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  25% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  30% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }
  35%,
  45% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * -1%));
  }
  40% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * 1%));
  }
  50% {
    clip-path: var(--clip-six);
    transform: translate(0, 0);
  }
  55% {
    clip-path: var(--clip-seven);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  60% {
    clip-path: var(--clip-seven);
    transform: translate(0, 0);
  }
  31%,
  61%,
  100% {
    clip-path: var(--clip-four);
  }
}

.ripple-item {
  animation: ripple;
}

.fade-enter {
  opacity: 0;
  transition: opacity 250ms;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 250ms;
}

.fade-exit.fade-exit-active {
  opacity: 0;
}

.bg-gradient {
  background-image: linear-gradient(
    180deg,
    #010b6c,
    #002780,
    #003f92,
    #0056a1,
    #0d6cae
  );
}

@media screen and (min-width: 768px) {
  .min-w-card {
    min-width: 480px;
  }
}

.bg-card {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
